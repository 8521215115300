body {
  background: whitesmoke;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.game {
  display: flex;
  flex-flow: column;
  text-align: center;
  flex-grow: 1;
  width: 100%;
}

.messageHolder {
  display: flex;
  font-family: "Lucida Console", monospace;
  min-height: 50%;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  margin-top: 30px;
}

.wordHolder {
  display: flex;
  flex-flow: row;
  margin: 0 15px;
}

.promptContainer {
  display: flex;
  flex-flow: column;
  margin: 3px;
  min-width: 3.4em;
  min-height: 3em;
  justify-content: flex-end;
  align-items: center;
}

.letter {
  position: fixed;
  font-size: 3em;
  color:#CCC4BC;
  padding-bottom: 7px;
}

.sequence {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #CCC4BC;
  margin: 1px;
}

.space {
  width: 5px;
  margin: 3px;
}

.dash {
  width: 9px;
  height: 5px;
  border-radius: 1px;
  background-color: #CCC4BC;
  margin: 1px;
}

.completedCodeSignal {
  background-color: black;
}

.hiddenCodeSignal {
  display: none;
}

.completedText {
  color: black;
}

.switchContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  /* min-height: 200px; */
}

.spinnerContainer {
  position: absolute;
  margin-top: 2px;
  background: transparent;
}

.resetSpinner {
  transform: rotate(270deg); 
  stroke: #E0E0E0;
  stroke-width: 7px;
  fill: transparent;
  stroke-linecap: round;
}

.switchButton {
  width: 150px;
  height: 150px;
  background: radial-gradient(black,#2C2C2C);
  border-radius: 50%;
  border: 5px solid #242424;
}

.pressed {
  background: radial-gradient(black,#4c4c4c);
  border: 5px solid #2C2C2C
}

.switchButton:focus {
  outline-style: none;
}

.createMessageContainer {
  display: flex;
  flex-flow: column;
  /* flex-grow: 2; */
  background: white;
  align-items: flex-start;
  font-size: larger;
  margin-top: 60px;
}

.messageInput {
  display: flex;
  flex-flow: row;
  justify-content: center;
  /* flex-grow: 2; */
  background: white;
  font-size: larger;
}

form {
  background: white;
  width: 100%;
}

.text-label {
  color: #A68823;
  background: white;
}

.text-input {
  border: 1px solid #CCC4BC;
  background: white;
  padding: 0px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  vertical-align: top;
  font-size: medium;
  padding: 10px 10px;
  margin: 0px 10px 0px 10px;
  max-width: 100%;
  font-family: "Lucida Console", monospace;
}

.settings {
  position: relative;
  padding: 10px;
  width: fit-content;
}

.hamburgerWrapper {
  position: relative;
  padding: 20px;
}

.control-holder {
  display: flex;
  position: relative;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.control-text {
  font-size: medium;
  padding-bottom: 6px;
  color: #A39C96;

}

.toggle {
  margin-left: 8px;
}

.toggle-input {
  display: none;
}

.toggle-label {
  display: inline-block;
  width: 48px;
  height: 24px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

.toggle-label::before,
.toggle-label::after {
  content: '';
  display: block;
  position: absolute;
  cursor: pointer;
}

.toggle-label::before {
  width: 100%;
  position: relative;
  height: 100%;
  background-color: #CCC4BC;
  border-radius: 9999em;
  transition: background-color 0.2s ease;
}

.toggle-label::after {
  top: -24px;
  position: relative;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.45);
  transition: left 0.08s ease;
}

.toggle-input:checked + .toggle-label::before {
  background-color: #DCCC3C;
}

.toggle-input:checked + .toggle-label::after {
  left: 24px;
}

.menuBar1, .menuBar2, .menuBar3 {
  position: absolute;
  width: 35px;
  height: 5px;
  background-color: #E0E0E0;
  margin: 6px 0;
  transition: .1s;
}

.menuBar1 {
  top: 5px;
}

.menuBar2 {
  top: 15px;
}

.menuBar3 {
  top: 25px;
}

.menuSlider1, .menuSlider2, .menuSlider3 {
  position: absolute;
  width: 4px;
  height: 10px;
  background-color: #E0E0E0;
  margin: 6px 0;
  border-radius: 3px;
  transition: .15s ease;
}

.menuSlider1 {
  top: 2px;
  left: 30px;
}

.menuSlider2 {
  top: 12px;
  left: 47px;
}

.menuSlider3 {
  top: 22px;
  left: 25px;
}

.settings-content {
  display: none;
  position: absolute;
  left: 25px;
  top: 55px;
  background-color: white;
  min-width: 200px;
  min-height: 100px;
  border-radius: 4px;
  box-shadow: -10px 5px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1;
}

.settings:hover > .settings-content {
  display: flex;
  flex-flow: column;
}

.settings:hover .menuBar1, .settings:hover .menuBar2, .settings:hover .menuBar3 {
  background-color: #CCC4BC;
}

.settings:hover .menuSlider1 {
  background-color: #CCC4BC;
  left: 25px;
}

.settings:hover .menuSlider2 {
  background-color: #CCC4BC;
  left: 35px;
}

.settings:hover .menuSlider3 {
  background-color: #CCC4BC;
  left: 45px;
}

.submitButton {
  max-width: 100px;
  background-color: #A68823;
  color: white;
  -webkit-appearance: none;
  font-size: medium;
  padding: 10px 10px;
  border: 1px solid transparent;
  border-radius: 8px;
  text-decoration: none;
}

.downloadButton {
  max-width: 100px;
  background-color: #A68823;
  color: white;
  -webkit-appearance: none;
  font-size: medium;
  padding: 10px 10px;
  margin: 10px 0 0 0;
  border: 1px solid transparent;
  border-radius: 8px;
  text-decoration: none;
}

@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
}

.scoreCard-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 20px;
  background: white;
  box-shadow: -10px 5px 20px rgba(0, 0, 0, 0.2);
  width: 60%;
  min-height: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.score-main {
  color: #A39C96;
  font-size: xx-large;
  margin: 5px 0px 10px 0px;
}

.score-secondary {
  background-color: white;
  color: #A39C96;
  font-size: medium;
}

.modal-close {
  max-width: 100px;
  background-color: #A68823;
  color: white;
  -webkit-appearance: none;
  font-size: medium;
  padding: 10px 10px;
  margin: 50px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.light-container {
  height: 30px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.light-on {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #EC142C;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 5px 1px, inset #5C0404 0 -2px 8px, rgba(255, 0, 0, .8) 0 2px 20px;
  transition: 0.5s ease;
}

.light-off {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #9C0404;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 5px 1px, inset #5C0404 0 -1px 9px, rgba(255, 0, 0, 0.25) 0 2px 8px;
  transition: 0.5s ease;
}

.square {
  height: 50px;
  width: 50px;
  background-color: indigo;
}

.hidden {
  display: none;
}

h1 {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}